<template>
    <div id="content" class="supervise-checklist" v-if="!reportView && canLoadList">
        <ViewConfigForm
            v-if="displayViewConfigForm"
            @view-created-or-edited="handleDisplayView"
            @on-view-deleted="setDefaultViewAsSelectedView"
            @on-cancel-button="closeViewConfigForm"
            :isEdit="configViewIsEdit"
            :selectedView="selectedView"
        />

        <div class="supervise-top-bar-actions">
            <!-- Show/Hide Filters Button, Views Dropdown Menu and Ediv View Button -->

            <div class="sidebar-filters-top-buttons">
                <!-- botón de ocultar filtros sin label -->
                <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />

                <template v-if="showViews && viewsList.length > 0">
                    <!-- menú desplegable de vistas -->
                    <ViewsDropdownMenu
                        v-if="!isSmallScreen"
                        :views="viewsList"
                        :selectedView="selectedView"
                        @on-view-selected="handleDisplayView"
                        @on-create-view="handleCreateView"
                        :newFiltersAppliedToAView="newFiltersAppliedToAView"
                    />
                    <!-- botón de editar vista -->
                    <Button v-if="showEditViewButton && !isSmallScreen" bType="editIcon" customClass="edit-view-button" @on-button-click="handleEditView" />
                    <!-- botón de crear una nueva vista -->
                    <Button
                        v-if="newFiltersAppliedToAView && !isSmallScreen"
                        bType="createIcon"
                        customClass="edit-view-button"
                        @on-button-click="handleCreateView"
                    />
                </template>
            </div>

            <!-- Table Top Buttons -->
            <div class="supervise-table-actions-buttons">
                <Button v-if="rowsAreSelected && !isMobile" bType="file" :bCallback="goToReport" :bLabel="pluralize()" />

                <Button bType="more" :bCallback="showMoreOptions" />

                <div class="more-options" v-if="moreOptions">
                    <Button
                        bType="file"
                        :bCallback="downloadCsv"
                        :bLabel="$tc('supervise.checklists.table_headers.download_csv', selected.length == 1 ? 1 : 2)"
                    />
                    <Button
                        bType="file"
                        :bCallback="downloadExcel"
                        :bLabel="$tc('supervise.checklists.table_headers.download_excel', selected.length == 1 ? 1 : 2)"
                        v-if="rowsAreSelected && isDlRepor()"
                    />
                    <Button v-if="showDownloadPdf" bType="print" :bCallback="downloadPDF" bLabel="nvgt.report.download_pdf" />
                </div>
            </div>
        </div>

        <div class="supervise-container" :class="{ 'with-trial': trialEndDate }">
            <!-- SIDEBAR FILTERS -->
            <div
                class="sidebar-filters"
                :class="{
                    'empty-results': items.length == 0,
                    'hidden-filters-sidebar': filtersSidebarIsHidden
                }"
            >
                <SuperviseChecklistsFiltersBox
                    :selectedView="selectedView"
                    :key="filterKey"
                    ref="filterChecklist"
                    @reset="showButtonReset"
                    :applyFilter="applyFilter"
                    :pagination="pagination"
                    :sortBy="sortBy"
                    :sortType="sortType"
                    :changePagination="changePagination"
                    @resetPageDatatable="top(true)"
                    @resetPagination="resetPagination"
                    @on-reset-filters="setDefaultViewAsSelectedView"
                    @filtersApplied="closeSidebarOnMobile"
                />
            </div>
            <!-- TABLE -->
            <div class="data-table scrollbar" :class="{ 'empty-results': items.length == 0 }">
                <v-data-table
                    :update:sort-by="sortTable()"
                    v-if="items"
                    v-model="selected"
                    :headers="headers"
                    fixed-header
                    :items="items"
                    sort-by="business_date"
                    :sort-desc="true"
                    :single-select="singleSelect"
                    hide-default-footer
                    show-select
                    :page.sync="pagination.page"
                    :items-per-page="pagination.itemsPerPage"
                    @page-count="pageCount = $event"
                    :options.sync="pagination"
                >
                    <template #item.title="item">
                        <div class="title-template" @click="goToReport(item.item)">
                            {{ item.item.title }}
                            <span v-if="!templateIsActive(item.item.template_id)" class="inactive-card"> {{ $t('template.inactive') }}</span>
                        </div>
                    </template>
                    <template #item.location_name="item">
                        <div class="location_name" @click="goToReport(item.item)">
                            <span>{{ item.item.location_name }}</span>
                        </div>
                    </template>
                    <template #item.timeframe="item">
                        <div class="timeframe" @click="goToReport(item.item)">
                            <span class="timeframe-hour" v-html="getTimeFrameHour(item.item.timeframe)"></span>
                            <span class="timeframe-name">{{ getTimeFrame(item.item.timeframe) }}</span>
                        </div>
                    </template>
                    <template #item.business_date="item">
                        <div class="date" @click="goToReport(item.item)">
                            <span class="timeframe-hour" v-html="spanishDateFormat(item.item.business_date)"></span>
                        </div>
                    </template>
                    <template #item.complete_date="item">
                        <div class="complete_date" @click="goToReport(item.item)">
                            <span v-if="item.item.complete_date === false">-</span>
                            <span v-else>{{ item.item.complete_date }}</span>
                        </div>
                    </template>
                    <template #item.score="item">
                        <template v-if="item.item.total == 0">
                            <div class="na">
                                {{ $t('nvgt.report.answer_na') }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="score" @click="goToReport(item.item)">
                                <span :class="scoreColors(item.item.score)">{{ item.item.score }}%</span>
                            </div>
                        </template>
                    </template>
                    <template #item.resume="item">
                        <div class="resume" @click="goToReport(item.item)">
                            <div class="summary">
                                <div v-if="!summaryIs0(item.item.resume.it)" class="summary-uncompleted-task" v-tooltip="$t('supervise.checklists.summary_3')">
                                    {{ item.item.resume.it }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_3')"
                                    src="~@/../public/img/task_uncompleted_inactive.svg"
                                />

                                <div v-if="!summaryIs0(item.item.resume.a)" class="summary-alerts" v-tooltip="$t('supervise.checklists.summary_2')">
                                    {{ item.item.resume.a }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_2')"
                                    src="~@/../public/img/task_warning_inactive.svg"
                                />

                                <div v-if="!summaryIs0(item.item.resume.is)" class="summary-incidents" v-tooltip="$t('supervise.checklists.summary_1')">
                                    {{ item.item.resume.is }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_1')"
                                    src="~@/../public/img/task_issue_inactive.svg"
                                />

                                <div v-if="!summaryIs0(item.item.resume.n)" class="summary-note" v-tooltip="$t('supervise.checklists.summary_4')">
                                    {{ item.item.resume.n }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_4')"
                                    src="~@/../public/img/task_note_inactive.svg"
                                />
                            </div>
                            <div class="delay" v-if="item.item.delay !== false">
                                {{ leftTime(item.item.delay) }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <div class="andy-datatable-empty" v-if="itemsLoaded">
                            <EmptyTable
                                buttonClass="btn-action"
                                @buttonAction="resetFilter"
                                buttonType="cancel"
                                :buttonText="showReset ? $t('assets.filter.delete') : undefined"
                                :title="$t('empty_table.users_title_supervise')"
                                type="supervise"
                                :description="$t('empty_table.issues_empty_filter')"
                            ></EmptyTable>
                        </div>
                    </template>
                </v-data-table>

                <div id="pagination" v-if="!(numItems == 0)">
                    <div class="totalItems">
                        <span class="total">
                            <strong class="total-results">{{ numItems }}</strong>
                            {{ $t('supervise.checklists.table_headers.results') }}
                        </span>
                    </div>
                    <v-pagination v-model="pagination.page" color="#2c52c4" :length="pages" :total-visible="maxPageVisibles"></v-pagination>
                    <div class="rows-page">
                        <v-select :items="rowsPerPage" @change="changedItemPerPage = true" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ReportChecklist :reports="aReportsID" @back="closeReport()" v-else-if="rowsAreSelected"></ReportChecklist>
</template>

<script>
import SuperviseChecklistsFiltersBox from '@/components/domain/checklist/supervise/SuperviseChecklistsFiltersBox'
import ReportChecklist from '@/components/domain/checklist/supervise/report'
import EmptyTable from '@/components/ui/EmptyTable'
import ViewsDropdownMenu from '@/components/domain/filtersViews/ViewsDropdownMenu.vue'
import ViewConfigForm from '@/components/domain/filtersViews/ViewConfigForm.vue'
import { TYPE } from '@/constants'

let previousRoute

export default {
    components: {
        SuperviseChecklistsFiltersBox,
        ReportChecklist,
        EmptyTable,
        ViewsDropdownMenu,
        ViewConfigForm
    },
    name: 'SuperviseChecklist',
    data() {
        return {
            type: TYPE.TASK,
            showReset: false,
            applyFilter: false,
            noChanges: false,
            // VIEW VARIABLES
            reportView: false,
            aReportsID: [],

            // DATATABLES
            values: false,
            singleSelect: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50, 100],
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('supervise.checklists.table_headers.date'),
                    value: 'business_date',
                    align: 'start',
                    width: '20%',
                    sortable: true,
                    sort: this.sortColumnDate,
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.checklists.table_headers.timeframe'),
                    value: 'timeframe',
                    width: '17%',
                    sortable: true,
                    sort: this.sortColumnTimeFrame,
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.checklists.table_headers.localization'),
                    value: 'location_name',
                    align: 'start',
                    width: '17%',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.checklists.table_headers.checklists'),
                    align: 'start',
                    sortable: true,
                    value: 'title',
                    width: '200px',
                    class: 'header-table'
                },

                {
                    text: this.$t('supervise.checklists.table_headers.score'),
                    value: 'score',
                    align: 'center',
                    sortable: true,
                    sort: this.sortColumnScore,
                    width: '100px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.checklists.table_headers.summary'),
                    value: 'resume',
                    align: 'center',
                    sortable: true,
                    width: '150px',
                    class: 'header-table'
                }
            ],
            options: {},
            filterKey: 1,
            sortBy: false,
            sortType: false,
            changePagination: false,
            changedItemPerPage: false,
            filtersSidebarIsHidden: false,

            // Filters VIEWS
            selectedView: null,
            displayViewConfigForm: false,
            configViewIsEdit: false,
            moreOptions: false,

            // for mobile
            windowWidth: window.innerWidth
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            const numItems = this.$store.getters['tasks/getNumItems']
            return numItems ? Math.ceil(numItems / this.pagination.itemsPerPage) : 1
        },
        numItems() {
            return this.$store.getters['tasks/getNumItems']
        },
        items() {
            var result = this.$store.getters['tasks/getList'](1)
            return Object.values(result)
        },
        itemsLoaded() {
            return this.$store.getters['tasks/getItemsLoaded']
        },
        canLoadList() {
            return this.$store.getters['tasks/getCanLoadList']
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        rowsAreSelected() {
            return this.selected.length > 0 ? true : false
        },

        // Filters VIEWS
        viewsList() {
            return this.$store.getters['filtersViews/getSuperviseChecklistsViewsList']
        },

        defaultView() {
            return this.$store.getters['filtersViews/getSuperviseChecklistsDefaultView']
        },

        showViews() {
            return this.$store.getters['login/getFilterViewsAvailable']
        },

        showEditViewButton() {
            const userRolLevel = this.$store.getters['getLevelUser']

            if (!this.selectedView) return false

            const viewType = this.selectedView.type

            // if is Standard View (type 1) never show the edit button
            if (viewType === 1) {
                return false
            }

            // if is Custom "Only for me" view (type 3) always show the edit button
            if (viewType === 3) {
                return true
            }

            // if is Custom "For the entire brand" (type 2) show edit button only to high level rol users:
            if (viewType === 2) {
                if (userRolLevel >= 7) {
                    return true
                } else return false
            }
        },

        newFiltersAppliedToAView() {
            const activeFilters = JSON.stringify(this.$store.getters['tasks/getFiltersActive'])

            if (this.selectedView) {
                const selectedViewFilters = JSON.stringify(this.selectedView.data)

                return activeFilters !== selectedViewFilters
            }
        },
        showDownloadPdf() {
            const reportPdf = parseInt(this.$store.getters['account/getReportPdf'])
            if (reportPdf == 1) {
                return true
            }
            return false
        },
        isMobile() {
            return this.windowWidth <= 767
        },
        isSmallScreen() {
            // includes mobile and tablets
            return this.windowWidth <= 900
        }
    },
    methods: {
        showMoreOptions() {
            this.moreOptions = !this.moreOptions
        },
        doPrint() {
            // this.$snackbar.close()
            this.$store.dispatch('tasks/supervisePdfPrintlog', { id: this.$route.params.id })
        },
        sortTable() {
            this.sortBy = this.pagination.sortBy && this.pagination.sortBy.length > 0 ? this.pagination.sortBy : false
            this.sortType =
                this.pagination.sortBy && this.pagination.sortBy.length > 0
                    ? this.pagination.sortDesc.length > 0 && this.pagination.sortDesc[0]
                        ? 'desc'
                        : 'asc'
                    : false
        },

        isDlRepor() {
            const config = this.$store.getters['login/getConfig']
            return config && config.dlReport === 'true'
        },

        resetPagination() {
            this.selected = []
            this.changePagination = false
            this.pagination.page = 1
            this.top(true)
        },
        resetFilter() {
            this.$refs.filterChecklist.resetFilters()
            this.showReset = false
        },
        showButtonReset(reset) {
            this.showReset = reset
        },

        templateIsActive(templateID) {
            var template = this.$store.getters['tasks/getFilterTemplate'](templateID)

            if (template && template.status == '-2') {
                return false
            }
            return true
        },
        top(cond) {
            // if (cond && !this.$route.params.noChangePageDatatable) {
            //     this.pagination.page = 1
            // }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        summaryIs0(summary) {
            if (summary == 0) {
                return true
            }
            return false
        },
        pluralize() {
            if (this.selected.length > 1) {
                return 'supervise.checklists.table_headers.show_informs'
            } else {
                return 'supervise.checklists.table_headers.show_inform'
            }
        },
        goToReport(itemClicked) {
            const amplitudeProps = {
                type: 'Checklist'
            }
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Use of reports', amplitudeProps)

            this.$overlay.show()
            if (itemClicked) {
                this.selected.push(itemClicked)
            }
            var reports = [...this.selected]

            this.aReportsID = []
            for (var index in reports) {
                reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
            }

            this.reportView = true
        },
        isPosibleToDownload() {
            const maxItems = 1000
            if (this.numItems > maxItems) {
                this.$popup.alertNewDesing({
                    title: this.$t('supervise.checklists.error_download_excel'),
                    message: this.$t('supervise.checklists.error_download_excel_rows', { maxItems: maxItems })
                })
                return false
            }

            return true
        },
        getReportsIdsList(listOfReportsObjs) {
            if (!listOfReportsObjs || listOfReportsObjs.length === 0) {
                return null
            }

            const ids = listOfReportsObjs.map((report) => {
                if (report.hasOwnProperty('id')) {
                    return report.id
                }
            })

            return ids
        },

        showExportByEmailPopup() {
            const self = this

            const user = self.$store.getters['loginUser/getLocalUser']
            const email = user.email

            const messageWithEmail = self.$t('supervise.reports.export.popup_email.message', { email: `<b>${email}</b>` })
            const messageWithoutEmail = self.$t('supervise.reports.export.popup_email.message2')

            self.$popup.messageWithButton({
                title: self.$t('supervise.reports.export.popup_email.title'),
                message: email ? messageWithEmail : messageWithoutEmail,
                callCancel: () => {
                    self.$popup.close()
                }
            })
        },

        handleExportReport() {
            const self = this

            // let reportsQuantity
            if (self.selected.length > 0) {
                const idsList = self.getReportsIdsList(self.selected)
                self.$store.commit('reports/setReportsToExportIds', idsList)
                // reportsQuantity = parseInt(idsList.length)
            } else {
                self.$store.commit('reports/setReportsToExportIds', null)
                // reportsQuantity = parseInt(self.numItems)
            }

            self.$popup.close()

            // display loader or loader popup
            // if (reportsQuantity >= 10) {
            //     self.$popup.exportLoading()
            // } else self.$overlay.loading()

            // display loader:
            self.$overlay.loading()

            self.$store.dispatch('reports/exportReports', { type: TYPE.TASK }).then((response) => {
                if (!response.status) {
                    // close popup or loader
                    // if (reportsQuantity >= 10) {
                    //     self.$popup.close()
                    // } else self.$overlay.hide()

                    // close loader
                    self.$overlay.hide()

                    // if is imposible to export, ask user to change de filters:
                    if (response.error.code == -2) {
                        self.$popup.messageWithButton({
                            title: this.$t('supervise.reports.export.popup_avoid.title'),
                            message: this.$t('supervise.reports.export.popup_avoid.message2'),
                            customClass: 'error',
                            callCancel: () => {
                                // close "more options" dropdown menu
                                self.moreOptions = false
                                // close popup
                                self.$popup.close()
                            }
                        })
                    } else {
                        // if error code is not -2, show the modal that says that pdfs will be sent by email:
                        self.showExportByEmailPopup()

                        // close "more options" dropdown menu
                        self.moreOptions = false
                    }

                    return
                } else {
                    // close popup or loader
                    // if (reportsQuantity >= 10) {
                    //     self.$popup.close()
                    // } else self.$overlay.hide()

                    // close loader
                    self.$overlay.hide()

                    // open PDFs in a new tab
                    window.open(response.url, '_blank')

                    // close "more options" dropdown menu
                    self.moreOptions = false
                }
            })
        },
        downloadExcel() {
            // this.$overlay.loading()
            const amplitudeProps = {}
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Download reports', {
                ...amplitudeProps,
                reports: this.selected.length
            })

            const self = this

            self.$overlay.show()

            const filters = this.$store.getters['tasks/getFiltersActive']

            if (this.selected.length > 0) {
                //Enviar ids
                this.aReportsID = []
                let reports = [...this.selected]
                let self = this

                for (let index in reports) {
                    reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
                }

                this.$store
                    .dispatch('tasks/downloadReportListExcel', {
                        ids: this.aReportsID
                    })
                    .then((file) => {
                        let filename = file.split('/').pop()
                        let link = document.createElement('a')
                        link.download = filename
                        link.href = file
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        self.$overlay.hide()
                    })
                    .catch((error) => {
                        console.error(error)
                        self.$overlay.hide()
                    })
            } else {
                if (this.isPosibleToDownload()) {
                    //Enviar filtros
                    const location = filters.locations ? Object.values(filters.locations).map(({ id }) => id) : ''
                    const template = filters.template ? Object.values(filters.template).map(({ id }) => id) : ''
                    const timeframe = filters.timeframe ? Object.values(filters.timeframe).map(({ id }) => id) : ''
                    const category = filters.category ? Object.values(filters.category).map(({ id }) => id) : ''
                    const groups = filters.territories ? Object.values(filters.territories).map(({ id }) => id) : ''

                    this.$store
                        .dispatch('tasks/downloadReportListExcel', {
                            location: location,
                            template: template,
                            timeframe: timeframe,
                            start_date: filters.type_date !== 'custom' ? filters.type_date : filters.start_date,
                            end_date: filters.end_date,
                            demora: filters.demora,
                            score: filters.score,
                            category: category,
                            pagenum: this.pagination.page,
                            number_items: this.pagination.itemsPerPage,
                            sortBy: this.sortBy,
                            sortType: this.sortType,
                            groups: groups
                        })
                        .then((file) => {
                            let filename = file.split('/').pop()
                            let link = document.createElement('a')
                            link.download = filename
                            link.href = file
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            this.$overlay.hide()
                        })
                        .catch((error) => {
                            console.error(error)
                            this.$overlay.hide()
                        })
                }
                this.$overlay.hide()
            }
        },

        downloadPDF() {
            const self = this

            let reportsQuantity
            if (self.selected.length > 0) {
                reportsQuantity = self.selected.length
            } else {
                reportsQuantity = parseInt(self.numItems)
            }

            const maxQuantAllowed = 10000

            // modal to inform that quantity is not allowed and suggest applying more filters
            if (reportsQuantity > maxQuantAllowed) {
                self.$popup.close()

                self.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_avoid.title'),
                    message: this.$t('supervise.reports.export.popup_avoid.message', { maxQuantAllowed: `<b>${maxQuantAllowed}</b>` }),
                    customClass: 'error',
                    callCancel: () => {
                        self.moreOptions = false
                        self.$popup.close()
                    }
                })
            } else if (reportsQuantity < 1 && reportsQuantity < maxQuantAllowed) {
                self.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_empty.title'),
                    message: this.$t('supervise.reports.export.popup_empty.message'),
                    customClass: 'error',
                    callCancel: () => {
                        self.moreOptions = false
                        self.$popup.close()
                    }
                })
            } else {
                // PDF display options modal
                self.$popup.PDFDisplayOptions({
                    callSave: this.handleExportReport, // calls de store
                    callCancel: () => {
                        self.moreOptions = false
                    },
                    config: {
                        quantity: reportsQuantity,
                        tool: 'task'
                    }
                })
            }
        },
        downloadCsv() {
            if (this.items.length == 0) {
                this.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_empty.title'),
                    message: this.$t('supervise.reports.export.popup_empty.message'),
                    customClass: 'error',
                    callCancel: () => {
                        this.moreOptions = false
                        this.$popup.close()
                    }
                })
                return
            }
            // this.$overlay.loading()
            const amplitudeProps = {}
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Download reports', {
                ...amplitudeProps,
                reports: this.selected.length
            })

            const self = this

            self.$overlay.show()

            const filters = this.$store.getters['tasks/getFiltersActive']

            if (this.selected.length > 0) {
                //Enviar ids
                this.aReportsID = []
                let reports = [...this.selected]
                let self = this

                for (let index in reports) {
                    reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
                }

                this.$store
                    .dispatch('tasks/downloadReportListCsv', {
                        ids: this.aReportsID
                    })
                    .then((file) => {
                        let filename = file.split('/').pop()
                        let link = document.createElement('a')
                        link.download = filename
                        link.href = file
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        self.$overlay.hide()
                    })
                    .catch((error) => {
                        console.error(error)
                        self.$overlay.hide()
                    })
            } else {
                if (this.isPosibleToDownload()) {
                    //Enviar filtros
                    const location = filters.locations ? Object.values(filters.locations).map(({ id }) => id) : ''
                    const template = filters.template ? Object.values(filters.template).map(({ id }) => id) : ''
                    const timeframe = filters.timeframe ? Object.values(filters.timeframe).map(({ id }) => id) : ''
                    const category = filters.category ? Object.values(filters.category).map(({ id }) => id) : ''
                    const groups = filters.territories ? Object.values(filters.territories).map(({ id }) => id) : ''

                    this.$store
                        .dispatch('tasks/downloadReportListCsv', {
                            location: location,
                            template: template,
                            timeframe: timeframe,
                            start_date: filters.type_date !== 'custom' ? filters.type_date : filters.start_date,
                            end_date: filters.end_date,
                            demora: filters.demora,
                            score: filters.score,
                            category: category,
                            pagenum: this.pagination.page,
                            number_items: this.pagination.itemsPerPage,
                            sortBy: this.sortBy,
                            sortType: this.sortType,
                            groups: groups
                        })
                        .then((file) => {
                            let filename = file.split('/').pop()
                            let link = document.createElement('a')
                            link.download = filename
                            link.href = file
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            this.$overlay.hide()
                        })
                        .catch((error) => {
                            console.error(error)
                            this.$overlay.hide()
                        })
                }
                this.$overlay.hide()
            }
        },

        closeReport() {
            this.selected = []
            this.reportView = false
            this.$store.commit('tasks/canLoadList', true)
            this.applyFilter = false
            this.$router.push({
                name: 'SuperviseChecklist',
                params: {
                    noChangePageDatatable: true
                }
            })
        },
        spanishDateFormat(valDate) {
            var timestampMilliseconds = valDate * 1000
            var localLocale = moment(timestampMilliseconds)
            localLocale.locale(false)
            return localLocale.format('ddd DD MMM Y ')
        },
        leftTime(valDate) {
            var num = valDate
            var hours = num / 60
            var rhours = Math.floor(hours)
            var minutes = (hours - rhours) * 60
            var rminutes = Math.round(minutes)
            var result = ''
            if (rhours == 0) {
                result =
                    this.$t('supervise.checklists.realized') +
                    ' ' +
                    rminutes +
                    ' ' +
                    this.$tc('assets.timing.minute', rminutes) +
                    ' ' +
                    this.$t('supervise.checklists.delay')
            } else if (rhours > 1) {
                result =
                    this.$t('supervise.checklists.realized') +
                    ' ' +
                    rhours +
                    ' ' +
                    this.$tc('assets.timing.hour', rhours) +
                    ' ' +
                    this.$t('assets.timing.connector') +
                    ' ' +
                    rminutes +
                    ' ' +
                    this.$tc('assets.timing.minute', rminutes) +
                    ' ' +
                    this.$t('supervise.checklists.delay')
                if (rminutes == 0) {
                    result = '+' + rhours + 'horas'
                }
            } else {
                result =
                    this.$t('supervise.checklists.realized') +
                    ' ' +
                    rhours +
                    ' ' +
                    this.$tc('assets.timing.hour', rhours) +
                    ' ' +
                    this.$t('assets.timing.connector') +
                    ' ' +
                    rminutes +
                    ' ' +
                    this.$tc('assets.timing.minute', rminutes) +
                    ' ' +
                    this.$t('supervise.checklists.delay')
                // if (rminutes == 0) {
                //   result = '+' + rhours + 'hora'
                // }
            }
            return result
        },

        load() {
            if (this.$route.params.noChangePageDatatable && this.items.length > 0) {
                this.$route.params.noChangePageDatatable = false
                return
            }
            var self = this

            self.$overlay.show()

            if (this.$route.params.id) {
                self.$store.commit('tasks/canLoadList', false)
                // self.$store.dispatch('tasks/loadFilters', {}).then(function () {
                //     self.goToReport({ id: self.$route.params.id })
                //     self.$overlay.hide()
                // })
                self.goToReport({ id: self.$route.params.id })
                self.$overlay.hide()
            } else {
                self.$store.commit('tasks/canLoadList', true)
                this.reportView = false
                this.selected = []

                const filterSites = this.$store.getters['filterLocations/getFilterLocation']()
                const filterTerritories = this.$store.getters['filterLocations/getFilterGroups']()
                const filterTemplates = self.$store.getters['tasks/getFilterTemplate']()
                const filterTimeframes = self.$store.getters['tasks/getFilterTimeframe']()

                const filtersHaveOptionsData =
                    Object.keys(filterTerritories).length > 0 &&
                    Object.keys(filterSites).length > 0 &&
                    Object.keys(filterTemplates).length > 0 &&
                    Object.keys(filterTimeframes).length > 0

                if (filtersHaveOptionsData) {
                    self.$store.dispatch('tasks/loadFilters', {}).then(function () {
                        self.applyFilter = true

                        if (previousRoute) {
                            if (previousRoute.includes('checklist/')) {
                                self.$refs.filterChecklist.applyFilters()
                            }
                        }

                        self.loadViews()

                        self.$overlay.hide()
                    })
                } else {
                    self.$store.dispatch('tasks/loadFilters', {}).then(function () {
                        self.$store.dispatch('employee/loadEmployees').then(function () {
                            self.loadViews()

                            self.$overlay.hide()
                        })
                    })
                }
            }

            if (this.items.length <= 0) {
                this.$store.commit('tasks/canLoadList', true)

                if (this.$refs.filterChecklist) {
                    this.$refs.filterChecklist.applyFilters()
                    //this.loadViews()
                }
            }
        },

        getTimeFrame(id) {
            var timeframe = this.$store.getters['tasks/getFilterTimeframe'](id)
            var result = timeframe.name
            if (id === 0) {
                result = ''
            }
            return result
        },
        getTimeFrameHour(id) {
            var timeframe = this.$store.getters['tasks/getFilterTimeframe'](id)
            var start_hour = timeframe.start_hour
            var end_hour = timeframe.end_hour
            if (typeof start_hour === 'number') {
                start_hour = start_hour * 60
                start_hour = moment(moment().format('YYY-MM-DD 00:00:00')).add(start_hour, 'm').format('HH:mm')
            }
            if (typeof end_hour === 'number') {
                end_hour = end_hour * 60
                end_hour = moment(moment().format('YYY-MM-DD 00:00:00')).add(end_hour, 'm').format('HH:mm')
            }

            var result = start_hour + ' a ' + end_hour
            if (id === 0) {
                var name = this.$store.getters['tasks/getFilterTimeframe']('0')
                result = "<span class='all-day'>" + name.name + '</span>'
            }
            return result
        },
        formatTime(value) {
            return moment(value).format('DD/MM/YYYY HH:mm') != moment('00/00/0000 00:00').format('DD/MM/YYYY HH:mm')
                ? moment(value).format('DD/MM/YYYY HH:mm')
                : '-'
        },
        scoreColors(score) {
            if (score >= 100) {
                return 'done'
            } else if (score > 75) {
                return 'progress'
            } else if (score > 25) {
                return 'warning'
            } else {
                return 'alert'
            }
        },
        sortColumnTimeFrame(a, b) {
            var startHourA = a
            var startHourB = b
            if (!a == 0) {
                var timeframeA = this.$store.getters['tasks/getFilterTimeframe'](a)
                startHourA = timeframeA.start_hour
            }
            if (!b == 0) {
                var timeframeB = this.$store.getters['tasks/getFilterTimeframe'](b)
                startHourB = timeframeB.start_hour
            }

            var timeframeB = this.$store.getters['tasks/getFilterTimeframe'](b)
            if (startHourA < startHourB) {
                return -1
            }
            return 1
        },
        sortColumnDate(a, b) {
            if (a < b) {
                return -1
            }
            return 1
        },
        sortColumnScore(a, b) {
            if (a < b) {
                return -1
            }
            return 1
        },
        changePage() {
            this.top(true)
            this.changePagination = true
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('settingsSuperviseChecklistsSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setInitialFiltersSidebarIsHiddenValue() {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('settingsSuperviseChecklistsSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        },

        // Filters VIEWS METHODS
        loadViews() {
            this.$store.dispatch('filtersViews/loadSuperviseChecklistsViews')
        },

        async handleDisplayView(view) {
            this.$overlay.show()

            const viewsAreUpdated = await this.$store.dispatch('filtersViews/loadSuperviseChecklistsViews')

            if (viewsAreUpdated) {
                const selectedView = await this.$store.getters['filtersViews/getSuperviseChecklistViewById'](view.id)

                this.selectedView = selectedView

                this.$store.commit('filtersViews/setSuperviseChecklistsSelectedView', this.selectedView)

                this.$overlay.close()
            }
        },

        handleCreateView() {
            this.configViewIsEdit = false
            this.displayViewConfigForm = true
        },

        handleEditView() {
            this.configViewIsEdit = true
            this.displayViewConfigForm = true
        },

        closeViewConfigForm() {
            this.displayViewConfigForm = false
            this.configViewIsEdit = false
        },

        setDefaultViewAsSelectedView() {
            this.selectedView = structuredClone(this.defaultView)

            this.$store.commit('filtersViews/setSuperviseChecklistsSelectedView', this.selectedView)
        },

        handleResize() {
            this.windowWidth = window.innerWidth
        },

        closeSidebarOnMobile() {
            if (this.isMobile) {
                this.filtersSidebarIsHidden = true
            }
        }
    },

    watch: {
        $route: 'load',
        options: {
            handler() {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options
                this.sortBy = typeof sortBy[0] !== 'undefined' ? sortBy[0] : false
                this.sortType = typeof sortDesc[0] !== 'undefined' ? (sortDesc[0] ? 'desc' : 'asc') : false
                this.filterKey++
            },
            deep: true
        },
        'pagination.itemsPerPage': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterChecklist.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },
        'pagination.page': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterChecklist.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },
        defaultView(defaultView) {
            if (!this.selectedView && this.items.length === 0 && defaultView) {
                this.setDefaultViewAsSelectedView()
            }
        },
        isSmallScreen(newVal, oldVal) {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('settingsSuperviseChecklistsSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        }
    },

    created() {
        this.load()

        this.selectedView = structuredClone(this.$store.getters['filtersViews/getSuperviseChecklistsSelectedView'])

        if (!this.selectedView && !this.newFiltersAppliedToAView) {
            this.setDefaultViewAsSelectedView()
        }

        window.addEventListener('resize', this.handleResize)
    },

    mounted() {
        if (this.$mq == 'portrait') {
            // By default, hide filters in portrait
            localStorage.setItem('settingsSuperviseChecklistsSidebarIsHiddenValue', JSON.stringify(true))
        }
        this.setInitialFiltersSidebarIsHiddenValue()
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.previousRoute = from.fullPath

            previousRoute = vm.previousRoute
        })
    },

    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.supervise-checklist {
    .btn-more {
        margin-left: 0px;
    }

    .more-options {
        position: absolute;
        top: 42px;
        right: 4px;
        padding: 3px 3px 3px 3px;
        z-index: 9;
        background-color: $color-neutral-200;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        @media (min-width: 768px) {
            top: 54px;
            right: 16px;
        }

        button {
            display: block;
            // min-width: 153px;
            margin: 0px;
            margin-bottom: 4px !important;
            &:last-child {
                margin-bottom: 0px !important;
            }
        }
    }
    .supervise-container {
        display: flex;
        align-items: stretch;

        gap: 0.5em;

        .data-table {
            height: 100%;

            .v-data-table,
            .v-data-table table {
                .done,
                .progress,
                .warning,
                .alert {
                    font-family: $text !important;
                }
                .done {
                    color: $color-success-500;
                }
                .progress {
                    color: $color-success-500;
                }
                .warning {
                    color: $color-warning-500;
                }
                .alert {
                    color: $color-error-500;
                }

                .complete_date,
                .score {
                    text-align: center;
                }
                .score .alert {
                    color: $color-error-500;
                    font-family: $text-bold;
                }

                .location_name span {
                    font-family: $text-medium !important;
                }

                .title-template {
                    display: block;
                    font-family: $text !important;
                    @include font-size(sm);
                }

                .hide {
                    display: none;
                }

                .na {
                    @include align-items();
                    @include border-radius(4px);
                    @include text-ellipsis();
                    @include background($image: img('error_main_s40.svg'), $size: 15px, $position: left 0px center);
                    @include font-size('sm');
                    padding: 3px 9px 3px 22px;
                    text-align: center;
                    // background-color: $color-primary-100;
                    // padding: 3px 9px 3px 30px;
                    font-family: $text-bold;
                    color: $color-primary-500;
                    width: min-content;
                    margin: 0 auto;
                }

                .end_date {
                    font-family: $text-bold;
                    color: $color-error-500;
                }
            }
        }

        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
